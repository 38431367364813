import "../assets/css/Portfolio.css";
import projectJson from "../assets/json/projects.json";

const Portfolio = () => {
  return (
    <section className="row portfolio" id="portfolio">
      <h2 className="section-title">Projects</h2>
      <div className="row portfolio-container">
        {projectJson.map((e, index) => (
          <div
            className="col-lg-4 col-md-5 col-8 mx-4 portfolio-item"
            key={index}
          >
            {e.github && e.github !== "" ? (
              <a
                href={e.github}
                className="item-img-container"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={require("/src/assets/images/" + e.img)}
                  alt={e.title}
                  className="item-img"
                />
              </a>
            ) : (
              <img
                src={require("/src/assets/images/" + e.img)}
                alt={e.title}
                className="item-img"
              />
            )}
            <div className="item-content">
              <h4 className="item-title">{e.title}</h4>
              <p className="item-desc">{e.desc}</p>
              <div className="tags">
                {e.tags &&
                  e.tags.length !== 0 &&
                  e.tags.map((tag, tagIndex) => (
                    <div className="tag" key={tagIndex}>
                      {tag}
                    </div>
                  ))}
              </div>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Portfolio;
