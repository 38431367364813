import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLinkedin } from "@fortawesome/free-brands-svg-icons";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import React from "react";
import "../assets/css/Contact.css";

const Contact = () => {
  return (
    <section className="row contact-section" id="contact">
      <h2 className="section-title">Contact</h2>
      <p className="desc">
        Let's connect! Feel free to to get in touch with me!
      </p>
      <div className="social">
        <div className="col-lg-4 col-sm-6 mx-4 social-item">
          <FontAwesomeIcon icon={faEnvelope} />
          <h5>
            Email: <a href="mailto:info@nicocheung.com">info@nicocheung.com</a>
          </h5>
        </div>

        <div className="col-lg-4 col-sm-6 mx-4 social-item">
          <FontAwesomeIcon icon={faLinkedin} />
          <h5>
            Let's Connect:{" "}
            <a
              href="https://www.linkedin.com/in/nico-cheung/"
              target="_blank"
              rel="noopener noreferrer"
            >
              nico-cheung
            </a>
          </h5>
        </div>
      </div>
    </section>
  );
};

export default Contact;
