import "../assets/css/Hero.css";
import Social from "./Social";
const Hero = () => {
  return (
    <section className="row hero" id="home">
      <div className="hero-content d-flex flex-column align-items-center justify-content-center">
        <h1 className="hero-highline">Hi, I'm Nico Cheung</h1>
        <h3 className="hero-desc">
          Final Year Fintech Student at The Chinese University of Hong Kong
        </h3>
        <Social />
      </div>
      <span className="pic-credit">Pic: NYC by Nico Cheung</span>
    </section>
  );
};

export default Hero;
