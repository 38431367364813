import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBriefcase,
  faGraduationCap,
} from "@fortawesome/free-solid-svg-icons";
import educationJson from "../assets/json/education.json";
import workJson from "../assets/json/work.json";
import "../assets/css/Timeline.css";

const Timeline = ({ type }) => {
  const typeMap = {
    work: { icon: faBriefcase, data: workJson, title: "Work Experience" },
    education: {
      icon: faGraduationCap,
      data: educationJson,
      title: "Education",
    },
  };
  const { icon, data, title } = typeMap[type] || {};
  if (!icon || !data || !Array.isArray(data)) {
    // Handle error when type or data is invalid
    return <div>No data available</div>;
  }
  return (
    <section className="row timeline" id={type}>
      <h2 className="section-title">{title}</h2>
      <div className="timeline-container">
        {data.map((e, index) => (
          <div className="timeline-block" key={index}>
            <div className="timeline-icon">
              <FontAwesomeIcon icon={icon} className="timelineIcon" inverse />
            </div>

            <div className="timeline-header">
              <h3 className="timeline-leftTitle">{e.leftTitle}</h3>
              <p className="timeline-date">{e.date}</p>
            </div>

            <div className="timeline-content">
              <h4 className="timeline-rightTitle">
                <a href={e.url} target="_blank" rel="noopener noreferrer">
                  {e.rightTitle}
                </a>
              </h4>
              <p className="timeline-subtitle">{e.subtitle}</p>
              {e.desc.split("\n").map((line, index) => (
                <p className="timeline-desc" key={index}>
                  {line}
                </p>
              ))}
              <div className="tags">
                {e.tags &&
                  e.tags.length !== 0 &&
                  e.tags.map((tag, index) => (
                    <div className="tag" key={index}>
                      {tag}
                    </div>
                  ))}
              </div>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Timeline;
