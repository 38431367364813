import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLinkedin, faGithub } from "@fortawesome/free-brands-svg-icons";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";

const Social = () => {
  return (
    <ul className="social">
      <li className="social-icon">
        <a
          href="mailto:info@nicocheung.com"
          className="social-icon-link mx-1"
          id="email-btn"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FontAwesomeIcon
            icon={faEnvelope}
            inverse
            className="social-icon-img"
          />
        </a>
      </li>
      <li className="social-icon">
        <a
          href="https://www.linkedin.com/in/nico-cheung/"
          className="social-icon-link mx-1"
          id="linkedin-btn"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FontAwesomeIcon
            icon={faLinkedin}
            inverse
            className="social-icon-img"
          />
        </a>
      </li>
      <li className="social-icon">
        <a
          href="https://github.com/nicockt"
          className="social-icon-link mx-1"
          id="github-btn"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FontAwesomeIcon
            icon={faGithub}
            inverse
            className="social-icon-img"
          />
        </a>
      </li>
    </ul>
  );
};

export default Social;
