import React, { useState } from "react";
import "../assets/css/Notes.css";
import noteJson from "../assets/json/notes.json";

const Notes = () => {
  const [selectedSubject, setSelectedSubject] = useState(noteJson[0]);

  return (
    <section className="row notes" id="notes">
      <h2 className="section-title">Study Notes</h2>
      <div className="row notes-container">
        <div className="col-lg-4 col-md-5 col-5 subject-container">
          {noteJson.map((e, index) => (
            <div
              key={index}
              onClick={() => setSelectedSubject(e)}
              className={`subject-item ${
                selectedSubject === e ? "active-subject" : ""
              }`}
            >
              {e.subject}
            </div>
          ))}
        </div>
        <div className="col-lg-4 col-md-5 col-5 file-container">
          <ul className="file-list">
            {selectedSubject.files.map((file, index) => (
              <div key={index} className="file-item">
                <a
                  href={require("/src/assets/files/" + file.file)}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="file-link"
                >
                  {file.name}
                </a>
              </div>
            ))}
          </ul>
        </div>
        <div className="col-lg-8 col-md-10 col-10 disclaimer">
          *Disclaimer: The notes are derived from various sources including
          books, lectures, and other references. They are intended solely for
          learning purposes. There is no guarantee that the content is 100%
          accurate or up-to-date.{" "}
        </div>
      </div>
    </section>
  );
};

export default Notes;
