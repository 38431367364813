import { Link } from "react-scroll";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleUp } from "@fortawesome/free-solid-svg-icons";
import Social from "./Social";
import "../assets/css/Footer.css";

const Footer = () => {
  return (
    <footer className="footer row">
      <div className="scroll-top-container">
        <Link
          to="hero"
          className="scroll-top pointer"
          spy={true}
          smooth={true}
          offset={-100}
          duration={500}
        >
          <FontAwesomeIcon icon={faAngleUp} className="top-arrow" />
        </Link>
      </div>
      <div className="copyright">
        <span> Copyright &copy; Nico Cheung</span>
      </div>
      <Social />
    </footer>
  );
};

export default Footer;
