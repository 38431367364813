import resume from "../assets/files/resume.pdf";
import "../assets/css/About.css";

import { Link } from "react-scroll";
const About = () => {
  return (
    <section className="row about" id="about">
      <div className="col-12">
        <h2 className="section-title">About</h2>

        <div className="profile">
          <div className="profile-img"></div>
          <p className="profile-desc lead">
            I'm a final year undergraduate majoring in Financial Technology at
            The Chinese University of Hong Kong. My primary interests include
            Financial Technology, Software Development, Artificial Intelligence
            and Natural Language Processing.
          </p>
        </div>
      </div>
      <div className="row skill-content">
        <div className="col-lg-6 col-sm-10">
          <h3>Skills</h3>
          <ul className="skill-bars">
            <li>
              <div className="progress percent90">
                <span className="skill-bar-percent">90%</span>
              </div>
              <strong>MS Suite</strong>
            </li>
            <li>
              <div className="progress percent90">
                <span className="skill-bar-percent">90%</span>
              </div>
              <strong>HTML/CSS/JS</strong>
            </li>
            <li>
              <div className="progress percent80">
                <span className="skill-bar-percent">80%</span>
              </div>
              <strong>Python</strong>
            </li>
            <li>
              <div className="progress percent70">
                <span className="skill-bar-percent">70%</span>
              </div>
              <strong>MERN</strong>
            </li>
            <li>
              <div className="progress percent70">
                <span className="skill-bar-percent">70%</span>
              </div>
              <strong>SQL</strong>
            </li>
            <li>
              <div className="progress percent60">
                <span className="skill-bar-percent">60%</span>
              </div>
              <strong>Solidity</strong>
            </li>
          </ul>
        </div>
      </div>

      <div className="row button-section">
        <div className="col-12">
          <Link
            to="contact"
            className="button mx-5"
            spy={true}
            smooth={true}
            offset={-100}
            duration={500}
          >
            {" "}
            Chat with Me{" "}
          </Link>
          <a
            href={resume}
            title="Download CV"
            target="_blank"
            rel="noopener noreferrer"
            className="button mx-2"
          >
            Download CV
          </a>
        </div>
      </div>
    </section>
  );
};

export default About;
