import "./assets/css/App.css";
import { Helmet } from "react-helmet";
import Contact from "./components/Contact";
import Hero from "./components/Hero";
import About from "./components/About";
import Timeline from "./components/Timeline";
import Portfolio from "./components/Portfolio";
import Footer from "./components/Footer";
import Notes from "./components/Notes";
import React, { useEffect } from "react";
import { Link } from "react-scroll";
import { Navbar, Nav } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

function App() {
  useEffect(() => {
    const nav = document.querySelector("nav");
    const navbarToggler = document.querySelector(".navbar-toggler");
    navbarToggler.addEventListener("click", () => {
      if (navbarToggler.classList.value === "navbar-toggler collapsed") {
        nav.classList.add("bg-dark", "shadow");
      } else {
        nav.classList.remove("bg-dark", "shadow");
      }
    });
    window.addEventListener("scroll", function () {
      if (window.scrollY > 100) {
        nav.classList.add("bg-dark", "shadow");
      } else {
        nav.classList.remove("bg-dark", "shadow");
      }
    });
  }, []);
  const collapseNavbar = () => {
    const navbarToggler = document.querySelector(".navbar-toggler");
    if (navbarToggler.classList.value === "navbar-toggler") {
      setTimeout(() => {
        navbarToggler.click();
      }, 500);
    }
  };
  return (
    <div className="App">
      <Helmet>
        <html lang="en" />
        <title>Nico Cheung's Web</title>
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta charSet="utf-8" />
        <meta
          name="description"
          content="Nico Cheung's Personal Website and Portfolio"
        />
      </Helmet>
      <header id="header">
        <Navbar collapseOnSelect expand="md" fixed="top" variant="dark">
          <Navbar.Toggle aria-controls="navbarNav" />
          <Navbar.Collapse id="navbarNav">
            <Nav className="text-uppercase ml-auto nav-content">
              <Nav.Item className="mx-2">
                <Link
                  to="hero"
                  className="nav-link pointer"
                  spy={true}
                  smooth={true}
                  offset={-100}
                  duration={500}
                  onClick={collapseNavbar}
                >
                  Home
                </Link>
              </Nav.Item>
              <Nav.Item className="mx-3">
                <Link
                  to="about"
                  className="nav-link pointer"
                  spy={true}
                  smooth={true}
                  offset={-100}
                  duration={500}
                  onClick={collapseNavbar}
                >
                  About
                </Link>
              </Nav.Item>
              <Nav.Item className="mx-3">
                <Link
                  to="education"
                  className="nav-link pointer"
                  spy={true}
                  smooth={true}
                  offset={-100}
                  duration={500}
                  onClick={collapseNavbar}
                >
                  Education
                </Link>
              </Nav.Item>
              <Nav.Item className="mx-3">
                <Link
                  to="work"
                  className="nav-link pointer"
                  spy={true}
                  smooth={true}
                  offset={-100}
                  duration={500}
                  onClick={collapseNavbar}
                >
                  Work
                </Link>
              </Nav.Item>
              <Nav.Item className="mx-3">
                <Link
                  to="portfolio"
                  className="nav-link pointer"
                  spy={true}
                  smooth={true}
                  offset={-100}
                  duration={500}
                  onClick={collapseNavbar}
                >
                  Projects
                </Link>
              </Nav.Item>
              <Nav.Item className="mx-3">
                <Link
                  to="notes"
                  className="nav-link pointer"
                  spy={true}
                  smooth={true}
                  offset={-100}
                  duration={500}
                  onClick={collapseNavbar}
                >
                  Notes
                </Link>
              </Nav.Item>
              <Nav.Item className="mx-3">
                <Link
                  to="contact"
                  className="nav-link pointer"
                  spy={true}
                  smooth={true}
                  offset={-100}
                  duration={500}
                  onClick={collapseNavbar}
                >
                  Contact
                </Link>
              </Nav.Item>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </header>
      <Hero />
      <About />
      <Timeline type="education" />
      <Timeline type="work" />
      <Portfolio />
      <Notes />
      <Contact />
      <Footer />
    </div>
  );
}

export default App;
